import React, { lazy, Suspense } from 'react';
import './App.css';
import Loading from './components/Loading';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { Switch } from 'react-router';
const Footer = lazy(() => import('./components/Footer'));
const Navbar = lazy(() => import('./components/Navbar'));
const Home = lazy(() => import('./Route/Home'));
const Overview = lazy(() => import('./Route/Overview'));
const Management = lazy(() => import('./Route/Management'));
const PrimarySChool = lazy(() => import('./Route/PrimarySchool'));
const MiddleSchool = lazy(() => import('./Route/MiddleSchool'));
const HighSchool = lazy(() => import('./Route/HighSchool'));
const InHouseTechnology = lazy(() => import('./Route/InHouseTechnology'));
const MoviesInTheClassroom = lazy(() => import('./Route/MoviesInTheClassroom'));
const Internship = lazy(() => import('./Route/Internship'));
const InTheNews = lazy(() => import('./Route/InTheNews'));
const IGCSE = lazy(() => import('./Route/IGCSE'));
const SAT = lazy(() => import('./Route/SAT'));
const Contact = lazy(() => import('./Route/Contact'));
const EnglishClass = lazy(() => import('./Route/Story_of_Levelfield/EnglishClass'));
const SocialScienceClass = lazy(() => import('./Route/Story_of_Levelfield/SocialScienceClass'));
const NoBoundaries = lazy(() => import('./Route/Story_of_Levelfield/NoBoundaries'));
const BeyondClass = lazy(() => import('./Route/Story_of_Levelfield/BeyondClass'));
const Twitter = lazy(() => import('./Route/Twitter_School/Twitter'));
const SchoolOfTomorrow = lazy(() => import('./Route/Unconventional_Ideas/SchoolOfTomorrow'));
const EliminateRote = lazy(() => import('./Route/Unconventional_Ideas/EliminateRote'));
const RoadLessTravelled = lazy(() => import('./Route/Unconventional_Ideas/RoadLessTravelled'));
const ScientificDifferences = lazy(() => import('./Route/Unconventional_Ideas/ScientificDifferences'));

const UniversityPlacements = lazy(() => import('./Route/UniversityPlacement'));
const Admissions = lazy(() => import('./Route/Admissions'));
export const App = () => {
   const [navActive, setActive] = React.useState('home');

   return (
      <div className='App'>
         <Suspense fallback={<Loading />}>
            <Navbar navActive={navActive} />
            <Switch>
               <Route exact path='/' render={(props) => <Home {...props} setActive={setActive} />} />
               <Route path='/overview' render={(props) => <Overview {...props} setActive={setActive} />} />
               <Route path='/management' render={(props) => <Management {...props} setActive={setActive} />} />
               <Route path='/primary-school' render={(props) => <PrimarySChool {...props} setActive={setActive} />} />
               <Route path='/middle-school' render={(props) => <MiddleSchool {...props} setActive={setActive} />} />
               <Route path='/high-school' render={(props) => <HighSchool {...props} setActive={setActive} />} />
               <Route
                  path='/in-house-technology'
                  render={(props) => <InHouseTechnology {...props} setActive={setActive} />}
               />
               <Route
                  path='/movies-in-the-classroom'
                  render={(props) => <MoviesInTheClassroom {...props} setActive={setActive} />}
               />
               <Route path='/path-one' render={(props) => <Internship {...props} setActive={setActive} />} />
               <Route path='/in-the-news' render={(props) => <InTheNews {...props} setActive={setActive} />} />
               <Route path='/igcse' render={(props) => <IGCSE {...props} setActive={setActive} />} />
               <Route path='/sat' render={(props) => <SAT {...props} setActive={setActive} />} />
               <Route path='/contact' render={(props) => <Contact {...props} setActive={setActive} />} />
               {/* <Route path='/english-class' render={(props) => <EnglishClass {...props} setActive={setActive} />} />
               <Route
                  path='/social-science-class'
                  render={(props) => <SocialScienceClass {...props} setActive={setActive} />}
               />
               <Route
                  path='/class-without-bounderies'
                  render={(props) => <NoBoundaries {...props} setActive={setActive} />}
               />
               <Route
                  path='/beyond-the-classroom'
                  render={(props) => <BeyondClass {...props} setActive={setActive} />}
               /> */}
               <Route path='/twitter' render={(props) => <Twitter {...props} setActive={setActive} />} />
               <Route
                  path='/school-of-tomorrow'
                  render={(props) => <SchoolOfTomorrow {...props} setActive={setActive} />}
               />
               <Route
                  path='/eliminate-rote-learning'
                  render={(props) => <EliminateRote {...props} setActive={setActive} />}
               />
               <Route
                  path='/road-less-travelled'
                  render={(props) => <RoadLessTravelled {...props} setActive={setActive} />}
               />
               <Route
                  path='/scientific-temper'
                  render={(props) => <ScientificDifferences {...props} setActive={setActive} />}
               />
               <Route
                  path='/university_placements'
                  render={(props) => <UniversityPlacements {...props} setActive={setActive} />}
               />
               <Route path='/admissions' render={(props) => <Admissions {...props} setActive={setActive} />} />
               <Redirect path='*' to='/overview' />
            </Switch>
            <Footer />
         </Suspense>
      </div>
   );
};

export default App;
